import { throttle } from 'lodash';
import { PanelsApiFactory } from '@wix/blocks-widget-services/panels';
import {
  EXPERIMENTS,
  SETTINGS_EVENT_ID,
  UPDATE_FULL_POST_STYLE,
} from '@wix/communities-blog-client-common';
import { OpenShowHidePanelParams } from '../../../blocks/common/elements-panel/showHideActions';
import categoriesLayout from '../../../components/Post Categories/blocksPanels/Categories Layout/.component.json';
import editCategories from '../../../components/Post Categories/blocksPanels/Edit Categories/.component.json';
import countersLayout from '../../../components/Post Counters New/blocksPanels/Counters Layout 1/.component.json';
import dividerLayout from '../../../components/Post Divider/blocksPanels/Divider Layout/.component.json';
import { openShowHideFooterPanel } from '../../../components/Post Footer/showHidePanel/showHideActions';
import headerLayout from '../../../components/Post Header/blocksPanels/Header Layout/.component.json';
import { openShowHideHeaderPanel } from '../../../components/Post Header/showHidePanel/showHideActions';
import metadataLayout from '../../../components/Post Metadata/blocksPanels/Metadata Layout/.component.json';
import ratingsLayout from '../../../components/Post Ratings/blocksPanels/Ratings Layout/.component.json';
import tagsLayout from '../../../components/Post Tags/blocksPanels/Tags Layout/.component.json';
import type { EditorAppContext } from '../../../types/editor-app-context.type';
import {
  COMPONENT_DATA_CHANGED,
  SITE_WAS_SAVED,
  WIDGET_GFPP_CLICKED,
} from '../constants/events';
import concurrentEditing from './concurrent-editing';
import { setFullpostStyleParams } from './fullpost-layout-design-service';
import { savePostPageStyle } from './post-page-style';

export const addEventListeners = async (context: EditorAppContext) => {
  const { sdk } = context;

  await sdk.addEventListener(SITE_WAS_SAVED, async () => {
    try {
      await savePostPageStyle(context);
    } catch (e) {}
  });

  const throttledSetParams = throttle(setFullpostStyleParams, 1000);

  await sdk.addEventListener(COMPONENT_DATA_CHANGED, async (event) => {
    const eventType = (event?.detail?.previousData as any)?.content?.[
      SETTINGS_EVENT_ID
    ]?.payload;

    if (eventType === UPDATE_FULL_POST_STYLE) {
      await concurrentEditing.withApproval(async () => {
        throttledSetParams(context);
      });
    }
  });

  if (context.flowAPI.experiments.enabled(EXPERIMENTS.SPLIT_POST_PAGE)) {
    const panelsApi = await new PanelsApiFactory().createPanelsApi({
      editorSDK: sdk,
      editorType: context.editorType,
      essentials: context.essentials,
    });

    await sdk.addEventListener(WIDGET_GFPP_CLICKED, async (event) => {
      const { id, componentRef } = event.detail;

      if (id === 'openShowHidePanel') {
        openShowHideHeaderPanel({
          editorSDK: sdk,
          widgetRef: componentRef,
          appToken: context.appToken,
        } as OpenShowHidePanelParams);
      }

      if (id === 'openShowHideFooterPanel') {
        openShowHideFooterPanel({
          editorSDK: sdk,
          widgetRef: componentRef,
          appToken: context.appToken,
        } as OpenShowHidePanelParams);
      }

      const openBlocksPanelPrefix = `open-blocks-panel: `;

      if (id === `${openBlocksPanelPrefix}<${headerLayout.id}>`) {
        await panelsApi.openBlocksPanel(headerLayout.id, componentRef);
      }

      if (id === `${openBlocksPanelPrefix}<${editCategories.id}>`) {
        await panelsApi.openBlocksPanel(editCategories.id, componentRef);
      }

      if (id === `${openBlocksPanelPrefix}<${tagsLayout.id}>`) {
        await panelsApi.openBlocksPanel(tagsLayout.id, componentRef);
      }

      if (id === `${openBlocksPanelPrefix}<${countersLayout.id}>`) {
        await panelsApi.openBlocksPanel(countersLayout.id, componentRef);
      }

      if (id === `${openBlocksPanelPrefix}<${categoriesLayout.id}>`) {
        await panelsApi.openBlocksPanel(categoriesLayout.id, componentRef);
      }

      if (id === `${openBlocksPanelPrefix}<${ratingsLayout.id}>`) {
        await panelsApi.openBlocksPanel(ratingsLayout.id, componentRef);
      }

      if (id === `${openBlocksPanelPrefix}<${dividerLayout.id}>`) {
        await panelsApi.openBlocksPanel(dividerLayout.id, componentRef);
      }

      if (id === `${openBlocksPanelPrefix}<${metadataLayout.id}>`) {
        await panelsApi.openBlocksPanel(metadataLayout.id, componentRef);
      }
    });
  }
};
