import type {
  ComponentRef,
  EditorSDK,
  ElementCategoryData,
  ElementData,
  ElementIdentifier,
} from '@wix/platform-editor-sdk';

export interface OpenShowHidePanelParams {
  editorSDK: EditorSDK;
  widgetRef: ComponentRef;
  appToken: string;
  data: { elementsData: ElementData[]; categoriesData: ElementCategoryData[] };
}

export const openShowHidePanel = async ({
  editorSDK,
  widgetRef,
  appToken,
  data,
}: OpenShowHidePanelParams) => {
  const getCollapsedRefComponentByRole = async (role: string) => {
    const [widgetRefHost] = await editorSDK.components.getAncestors(appToken, {
      componentRef: widgetRef,
    });
    const collapsedRefComponents =
      await editorSDK.components.refComponents.getCollapsedRefComponents(
        appToken,
        {
          componentRef: widgetRefHost,
          // @ts-expect-error temp until types are GAed
          includeInnerCollapsed: true,
        },
      );
    const collapsedRefComponent = collapsedRefComponents.filter(
      (comp) => comp.role === role,
    );

    return collapsedRefComponent[0]?.componentRef;
  };

  const getCompToHide = async (componentRef: ComponentRef) => {
    const type = await editorSDK.components.getType(appToken, {
      componentRef,
    });
    return type.includes('AppWidget')
      ? (await editorSDK.components.getAncestors(appToken, { componentRef }))[0]
      : /* istanbul ignore next reason: we don't hide whole widget */ componentRef;
  };

  const showComp = async (componentRef: ComponentRef) => {
    await editorSDK.components.refComponents.expandReferredComponent(appToken, {
      componentRef,
    });
    return editorSDK.application.livePreview.refresh(appToken, {
      shouldFetchData: false,
      source: 'AFTER_DB_CHANGE',
    });
  };

  const hideComp = async (componentRef: ComponentRef) => {
    await editorSDK.components.refComponents.collapseReferredComponent(
      appToken,
      {
        componentRef,
      },
    );
  };

  const addCompHandler = async (
    { role }: ElementIdentifier,
    compRef?: ComponentRef,
  ) => {
    const componentRef =
      compRef || (await getCollapsedRefComponentByRole(role));
    return showComp(componentRef);
  };

  const removeCompHandler = async (compRef: ComponentRef) => {
    const compToHide = await getCompToHide(compRef);
    return hideComp(compToHide);
  };

  return editorSDK.editor.openElementsPanel(appToken, {
    widgetRef,
    categoriesData: data.categoriesData,
    elementsData: data.elementsData,
    addComponentHandler: (elementIdentifier, compRef) =>
      addCompHandler(elementIdentifier, compRef),
    removeComponentHandler: removeCompHandler,
  });
};
