import {
  OpenShowHidePanelParams,
  openShowHidePanel,
} from '../../../blocks/common/elements-panel/showHideActions';
import { elementsData, categoriesData } from './showHideData';

export const openShowHideHeaderPanel = async ({
  editorSDK,
  widgetRef,
  appToken,
}: OpenShowHidePanelParams) =>
  openShowHidePanel({
    editorSDK,
    widgetRef,
    appToken,
    data: { elementsData, categoriesData },
  });
